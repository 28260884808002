import {
  IconButton, SwipeableDrawer, List, ListItem, ListItemText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import routes from '../../routes';
import { useActiveTab } from '../../hooks/useActiveTab';

const useStyles = makeStyles((theme) => ({
  toolbarMargin: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: '4rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '4rem',
    },
  },
  drawerIcon: {
    height: 50,
    width: 50,
  },
  drawerIconContainer: {
    marginLeft: 'auto',
    color: 'white',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  drawer: {
    backgroundColor: theme.palette.common.blue,
  },
  drawerItem: {
    ...theme.typography.tab,
    color: 'white',
    opacity: 0.7,
  },
  drawerItemSelected: {
    opacity: 1,
  },
  drawerItemEstimate: {
    backgroundColor: theme.palette.common.orange,
    opacity: 1,
  },
}));

export function MobileNav() {
  const { activeTab } = useActiveTab();
  const classes = useStyles();
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  return (<>
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      open={isDrawerOpen}
      onOpen={() => setIsDrawerOpen(true)}
      onClose={() => setIsDrawerOpen(false)}
      classes={{
        paper: classes.drawer,
      }}
    >
      <div className={classes.toolbarMargin} />
      <List disablePadding>
        {routes.map(({ path, label, tabId }) => (
          <ListItem
            key={label}
            component={Link}
            to={path}
            divider
            button
            onClick={() => {
              setIsDrawerOpen(false);
            }}
            selected={activeTab === tabId}
            classes={{ selected: classes.drawerItemSelected }}
            className={classes.drawerItem}
          >
            <ListItemText disableTypography>{label}</ListItemText>
          </ListItem>
        ))}
        <ListItem
          // component={Link}
          // to={'/'}
          divider
          button
          classes={{ root: classes.drawerItemEstimate }}
          className={classes.drawerItem}
        >
          <ListItemText disableTypography>Free Estimate</ListItemText>
        </ListItem>
      </List>
    </SwipeableDrawer>
    <IconButton
      onClick={() => setIsDrawerOpen(!isDrawerOpen)}
      disableRipple
      className={classes.drawerIconContainer}
      size="large">
      <MenuIcon className={classes.drawerIcon} />
    </IconButton>
  </>);
}
